import React, { Component } from 'react'

import { connect } from 'react-redux';
import DropDown from './DropDown'
import { fetchHorseDetail } from '../../_actions/page-detail.actions'
import { sortNamesAlphabetically, handleViewNote } from '../_utils'

import iconSortArrow from '../../_img/icon-sort-arrow.svg'
import iconDownArrow from '../../_img/icon-arrow-down.svg'

class StallionsTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentTableEntities: null,
      currentTab: null,
      nameOrderAsc: true
    }

    this.MASTER_TABLE_ENTITES = []
    this.isMobile = null
    this.checkIfMobile = this.checkIfMobile.bind(this)
  }

  componentDidMount() {

    this.MASTER_TABLE_ENTITES = [...this.props.tableData.tableEntities]
    this.isMobile = window.matchMedia('(max-width: 767px)').matches
    window.addEventListener('resize', this.checkIfMobile)
    this.props.fetchHorseDetail();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkIfMobile)
  }

  checkIfMobile() {
    this.isMobile = window.matchMedia('(max-width: 767px)').matches
    this.forceUpdate()
  }

  handleNameSort() {
    this.setState({
      nameOrderAsc: !this.state.nameOrderAsc,
      currentTableEntities: sortNamesAlphabetically(!this.state.nameOrderAsc, this.state.currentTableEntities)
    })
  }

  closeAllNotes() {
    [...document.querySelectorAll('.table_item-row.is-active')]
      .forEach(item => {
        item.removeAttribute('style')
        item.classList.remove('is-active')
        item.querySelector('.table_view-note-btn span').innerHTML = 'View Note'
        for (let i = 0; i < item.children.length; i++) {
          item.children[i].removeAttribute('style')
          item.children[i].classList.remove('is-active')
        }
      })
  }

  setCurrentTab(currentTab) {
    this.closeAllNotes()
    this.setState({
      currentTab
    })
    this.props.fetchHorseDetail(currentTab)
  }

  tableHeadingMap(item, idx, arr) {
    if (this.isMobile && idx > 0) {
      return null
    }
    return (
      <React.Fragment key={idx}>
        <div className="col">
          {item}
          {idx === 0 &&
            <div onClick={this.handleNameSort.bind(this)}
              className={`table_sort-alphabetical-btn${this.state.nameOrderAsc ? ' is-asc' : ' is-desc'}`}>
              <img src={iconSortArrow} />
            </div>}
        </div>
        {(idx === arr.length - 1) &&
          <div className="col"></div>}
      </React.Fragment>
    )
  }

  loadNewHorse(id) {
    this.props.history.push(`/horses/detail?id=${id}`)
  }

  sireTableEntitiesMap(item, idx) {
    return (
      <div onClick={() => { this.loadNewHorse(item.detailID) }} key={idx} className="table_item-row">
        <div className="row pad-x-15px">
          {item.tableCells.map((entityItem, idx, arr) => {
            return (
              <React.Fragment key={idx}>
                <div className="col d-flex justify-content-between d-md-block">
                  {this.state.isMobile && (
                    <div>
                      {this.props.pageDetailData.details.sireDescendants.tableHeadings[idx]}
                    </div>
                  )}
                  <div>
                    {(idx === 7 || idx === 8) && (
                      <span className="dollar-sign-margin">$</span>
                    )}
                    {entityItem}
                  </div>
                </div>
                {idx === arr.length - 1 && (
                  <div
                    onClick={handleViewNote}
                    className="col table_view-note-btn"
                  >
                    <span>View Note</span>
                    <img src={iconDownArrow} />
                  </div>
                )}
              </React.Fragment>
            )
          })}
        </div>
        <hr className="first-hr" />
        <div className="table_item-note">
          <div className="d-flex">
            <div className="spacer-15px" />
            <div>
              <div className="table_note-label">Notes</div>
              <div
                className="table_note-body"
                dangerouslySetInnerHTML={{ __html: item.note }}
              />
            </div>
          </div>
          <hr />
        </div>
      </div>
    )
  }

  render() {
    return (

      <section>
        <DropDown 
          stallions={this.props.tableData.tableEntities}
          setCurrentTab={this.setCurrentTab.bind(this)}
          isMobile={this.props.isMobile}
          history={this.props.history}
        />
        <div className="table">
          <div className="container-fluid">
                  <div className="table">
                  <div className="row table_heading-row">
                      {this.props.tableData.tableHeadings && this.props.tableData.tableHeadings.map(this.tableHeadingMap.bind(this))}
                      </div>
                    {this.state.currentTab && this.props.pageDetailData && this.props.pageDetailData.details.id &&
                    <div>
                      {this.props.pageDetailData.details.sireDescendants.tableEntities.map(this.sireTableEntitiesMap.bind(this))}
                      </div>
                    }
                  </div>
          </div>
        </div>
      </section>
    )
  }
}

const mapStateToProps = (state) => ({
  pageDetailData: state.pageDetailData,
  
})

const mapDispatchToProps = {
  fetchHorseDetail,
}

export default connect(mapStateToProps, mapDispatchToProps)(StallionsTable)